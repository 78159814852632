
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss?v=1.3.0";
import "assets/demo/demo.css?v=1.3.0";
// pages
import NucleoIcons from "views/NucleoIcons.js";
import LandingPage from "views/examples/LandingPage.js";

import ProfilePage from "views/examples/ProfilePage.js";
import ProfilePage1 from "views/examples/ProfilePage1.js";
import ProfilePage3 from "views/examples/ProfilePage3.js";
import ProfilePage4 from "views/examples/ProfilePage4.js";
import Contact from "views/examples/Contact.js";
import Services from "views/examples/Services.js";
import Course from "views/examples/Course.js";
import Index from "views/Index.js";
import NFC from "views/examples/NFC";


// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path="/" render={(props) => <Index {...props} />} />


      <Route
        path="/nucleo-icons"
        render={(props) => <NucleoIcons {...props} />}
      />
      <Route
        path="/landing-page"
        render={(props) => <LandingPage {...props} />}
      />
      <Route
        path="/team-nour"
        render={(props) => <ProfilePage {...props} />}
      />
      <Route
        path="/team-hasan"
        render={(props) => <ProfilePage4 {...props} />}
      />
      <Route
        path="/team-1"
        render={(props) => <ProfilePage1 {...props} />}
      />
      <Route
        path="/team-nina"
        render={(props) => <ProfilePage3 {...props} />}
      />
      <Route
        path="/contact"
        render={(props) => <Contact {...props} />}
      />
      <Route
        path="/services"
        render={(props) => <Services {...props} />}
      />
      <Route
        path="/course"
        render={(props) => <Course {...props} />}
      />
      <Route
        path="/nfc"
        render={(props) => <NFC {...props} />}
      />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
