
import React from "react";

// reactstrap components
import { Row, Container } from "reactstrap";

function DemoFooter() {
  return (
    <footer className="footer footer-black footer-white section-dark" >
      <Container>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li>
                <a style={{ color: "#FFFFFF" }}
                  href="/"

                >
                  Tri-Advise
                </a>
              </li>
              <li>
                <a style={{ color: "#FFFFFF" }}
                  href="/services"
                  target="_blank"
                >
                  NFC Card
                </a>
              </li>
              <li>
                <a style={{ color: "#FFFFFF" }}
                  href="/course"
                  target="_blank"
                >
                  Course
              </a>
              </li>
              <li>
                <a style={{ color: "#FFFFFF" }}
                  href="/services"
                  target="_blank"
                >
                  Services
            </a>
              </li>
              <li>
                <a style={{ color: "#FFFFFF" }}
                  href="/contact"
                  target="_blank"

                >
                  Contact
                </a>
              </li>
            </ul>
          </nav>
          <div className="credits ml-auto">
            <span className="copyright" style={{ color: "#FFFFFF" }}>
              © {new Date().getFullYear()}, {" "}
              <i className="fa fa-heart heart" /> by Tri-Advise
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
