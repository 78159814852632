import React from 'react'
import emailjs from 'emailjs-com';


// reactstrap components
import {
    Button,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
  } from "reactstrap";
import ContactPageHeader from "components/Headers/ContactPageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import Chat from './Chat';
import ServicesNavbar from 'components/Navbars/ServicesNavbar';


const Contact = () => {


  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('gmail', 'nour', e.target, 'user_5meN3rp2zzqeUD2oTp7Pq')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  }

    return (
        <div>
        <ServicesNavbar/>
        <ContactPageHeader/>

        <Chat/>

        <div className="section landing-section">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h2 className="text-center"><b>Keep in touch?</b></h2>
              <br/>
              <h5 className="text-center">For further questions, please email tri-advise@outlook.com or contact using our contact form. </h5>
              <Form className="contact-form" onSubmit={sendEmail}>
                <Row>
                  <Col md="6">
                    <label style={{color:"black"}}>Name</label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Full name" type="text" name="name"/>
                    </InputGroup>
                  </Col>
                  <Col md="6">
                    <label style={{color:"black"}}>Email</label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="Email" type="text" name="email" />
                    </InputGroup>
                  </Col>
                </Row>
                <label style={{color:"black"}}>How can we help?</label>
                <Input
                  placeholder="Describe your issue in at least 250 characters"
                  type="textarea"
                  rows="4"
                  name="message"
                />
                <Row>
                  <Col className="ml-auto mr-auto" md="4">
                    <Button className="btn-fill" color="danger" size="lg" type="submit" value="Send">
                      Send Message
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
      <DemoFooter/>
      
      </div>
      
    )
}

export default Contact
