
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionDark2() {
  return (
    <>
      <div className="section section-dark">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title" color="danger"><b>Our Vision</b> </h2>
              <br />
              
              <p className="description" style={{fontSize:"17px" , fontWeight:"400"}}>
            
              We strive to provide the best innovative technology today for a better tomorrow. 
              We are committed to sustainable business, excellence in quality and reliable services. 
              We conduct our business with integrity to deliver optimal values to stakeholders.
              <br/>
              We consider our experience as a growing component whereby we fully devote ourselves to 
              bringing the Marketing industry to a whole new level. Our rich years of experience in the 
              field have granted us a fully developed “know-how” structure that we build on to remain 
              one of the biggest digital marketing Solutions Providers in the world.
              <br/>
              Imagine yourself living in a cave, you need a stick to survive it is useful back there 
              to kill a snack or for defense. Nowadays, Technology trends are this era stick that is 
              usefull for you Your smartphone and your social media accounts are not a threat for you 
              unless you direct it in the right way.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionDark2;
