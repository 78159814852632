import React from 'react'
import { useEffect } from 'react';
import tawkTo from "tawkto-react";

const tawkToPropertyId = '60d3a9667f4b000ac0393950'

// Direct Chat Link
// https://tawk.to/chat/tawkToPropertyId/tawkToKey

const tawkToKey = '7c5f1867e9d38af713668dec8094a2eb374b12eb'


export default function Chat() {
    useEffect(() => {
        tawkTo(tawkToPropertyId, tawkToKey)
    }, [])
    return (
        <div>
            
        </div>
    )
}

