
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionDark() {
  return (
    <div id="our-mission">
      <div className="section section-dark " >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title" color="danger"><b>Our Mission</b> </h2>
              <br />
              
              <p className="description" style={{fontSize:"17px" , fontWeight:"400"}}>
              Being an innovative digital marketing solutions provider, 
              we aim to position Tri-Advise as one of the Fintech leaders in the world. 
              We deliver the promise of the best possible digital innovative technologies. 
              Our devoted agile multi-cultural teams are always ready to go the extra mile 
              in providing the best and the most reliable solutions. Tri-Advise is recognized 
              for its in-house development of customized state-of-the-art products and services. 
              We pledge to provide our customers, operators, business partners with quality 
              services that will guarantee the optimal experience and return on investment. 
              Tri-Advise is an employer of choice, conducting its business activities based 
              on the best practices according to the international standards of corporate 
              governance and business ethics.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default SectionDark;
