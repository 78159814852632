
import React from "react";

// reactstrap components
import {
    Button,
    Container,
    Row,
    Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import NFCHeader from "components/Headers/NFCHeader.js";

import DemoFooter from "components/Footers/DemoFooter.js";


function NFC() {
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("profile-page");
        return function cleanup() {
            document.body.classList.remove("profile-page");
        };
    });
    return (
        <>
            <ExamplesNavbar />
            <NFCHeader />

            <div id="our-mission">
                <div className="section " >
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="8">
                                <h3 className="title" color="danger"><b>High-quality NFC and NFC Golden Business Cards, Now Available </b> </h3>
                                <br />

                                <p className="title" style={{ fontSize: "17px", fontWeight: "400" }}>
                                    <br />
                                    At Tri-Advise, we know how important it is to have the right cards for your business.
                                    <br />
                                    That’s why we now offer NFC and NFC golden business cards to our customers.


  <br />
                                    <br />

                                    Our NFC and NFC golden business cards are professionally designed to provide your business with a unique edge. With these cards, you can easily access your information, store data, and share resources with your customers. Plus, they’re stylish and high-quality, making them perfect for any business setting.
  <br />
                                    <br />
                                    Near-field communication (NFC) business cards enable you to share your contact information with a single tap.
                                    <br />
                                    NFC business cards have two components: a digital business card and an NFC tag.
                                    You can choose between Metal ( Gold, Black or Titanium ) PVC or organic Bamboo.
                                    <br />
                                    <br />

                                    Gather your business details, social media accounts links, website, whatsapp business account and much more, in one place and with touchless cards.
                                    <br />
                                    <br />

                                    <div id="images">
                                        <Container>
                                            <div className="header">
                                                <h3 style={{ fontSize: "32px", fontWeight: "400" }}>The Cards</h3>
                                            </div>
                                            <Row>
                                                <Col md="6" sm="5">
                                                    <h4 className="images-title" style={{ fontSize: "24px", fontWeight: "400" }}>NFC</h4>
                                                    <img
                                                        alt="..."
                                                        className="img-rounded img-responsive"
                                                        src={require("assets/img/nfc2.jpg").default}
                                                    />
                                                    <div className="img-details">
                                                        <div className="author">

                                                        </div>

                                                    </div>
                                                </Col>
                                                <Col md="6" sm="5">
                                                    <h4 className="images-title" style={{ fontSize: "24px", fontWeight: "400" }}>NFC Gold</h4>
                                                    <img
                                                        alt="..."
                                                        className="img-rounded img-responsive"
                                                        src={require("assets/img/nfc1.jpg").default}
                                                    />
                                                    <div className="img-details">
                                                        <div className="author">

                                                        </div>

                                                    </div>
                                                </Col>


                                            </Row>
                                        </Container>
                                    </div>


                                    <br />


  Order your own NFC and NFC golden business cards from Tri-Advise today. We offer a variety of designs to suit your needs and you can rest assured that your cards will be of the highest standard. With Tri-Advise, you can trust that you’ll have the perfect cards for your business.                            <br />
                                    <br />


                                </p>
                                <Button
                                    className="btn-round"
                                    color="danger"
                                    href="https://api.whatsapp.com/send/?phone=%2B96181906656&text&type=phone_number&app_absent=0"
                                    target="_blank"
                                >
                                    Order Now
                </Button>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>


            <DemoFooter />
        </>
    );
}

export default NFC;
