
import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function SectionNucleoIcons() {
  return (
    <>
      <div className="section section-dark section-nucleo-icons">
        <Container>

          <Row>
            <Col lg="6" md="12">
              <br />
              <br />

              <h2 className="title"><b>Tri-Influencer</b> </h2>

              <p className="description" style={{ fontSize: "17px", fontWeight: "400" }}>
                We gladly present our new service, Influencers Startup!
              <hr />
              With Tri-Influencer, you can achieve your dream of becoming an influencer on social media. It is the first service available worldwide for anyone aged 15+ who dreams of celebrity, millions of followers, and more.
              <br />
                <br />
              We guarantee thousands of followers in the early stages of the program, while millions will certainly be your destiny.
              </p>
              <br />
              <Button
                className="btn-round"
                color="danger"
                href="https://www.instagram.com/tri_nfluencer/?igshid=YmMyMTA2M2Y%3D"
                target="_blank"
              >
                Join Tri-Influencer

              </Button>

            </Col>
            <Col lg="6" md="12">
              <br />
              <br />
              <br />

              <div className="icons-container">
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/faces/f.png").default}
                />
              </div>
            </Col>
          </Row>
        </Container>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>{" "}
    </>
  );
}

export default SectionNucleoIcons;
