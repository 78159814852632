import React from 'react'

import InfiniteCarousel from 'react-leaf-carousel';
const Clients2 = () => {
  return (

    <div>
      <InfiniteCarousel
        breakpoints={[
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
        ]}
        dots={false}
        showSides={true}
        sidesOpacity={.0}
        sideSize={.3}
        swipe={true}
        arrows={true}
        slidesToScroll={1}
        slidesToShow={5}
        scrollOnDevice={true}
        autoplay={true}
        animationDuration={600}
      >
        <div>
          <img
            alt=''
            src={require("assets/img/faces/f1.jpg").default} />
        </div>

        <div>
          <img
            alt=''
            src={require("assets/img/faces/f2.png").default} />
        </div>
        <div>
          <img
            alt=''
            src={require("assets/img/faces/f3.jpg").default} />
        </div>
        <div>
          <img
            alt=''
            src={require("assets/img/faces/f4.jpg").default} />
        </div>
        <div>
          <img
            alt=''
            src={require("assets/img/faces/1.png").default} />
        </div>
        <div>
          <img
            alt=''
            src={require("assets/img/faces/2.png").default} />
        </div>
        <div>
          <img
            alt=''
            src={require("assets/img/faces/3.png").default} />
        </div>
        <div>
          <img
            alt=''
            src={require("assets/img/faces/4.png").default} />
        </div>
        <div>
          <img
            alt=''
            src={require("assets/img/faces/5.png").default} />
        </div>
        <div>
          <img
            alt=''
            src={require("assets/img/faces/6.png").default} />
        </div>
        <div>
          <img
            alt=''
            src={require("assets/img/faces/7.png").default} />
        </div>
        <div>
          <img
            alt=''
            src={require("assets/img/faces/8.png").default} />
        </div>
        <div>
          <img
            alt=''
            src={require("assets/img/faces/9.png").default} />
        </div>
        <div>
          <img
            alt=''
            src={require("assets/img/faces/10.png").default} />
        </div>
        <div>
          <img
            alt=''
            src={require("assets/img/faces/11.png").default} />
        </div>

      </InfiniteCarousel>
    </div>
  )
}

export default Clients2;
