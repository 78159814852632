
import React from "react";

// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ServicesNavbar from "components/Navbars/ServicesNavbar.js";
import ProfilePageHeader1 from "components/Headers/ProfilePageHeader1.js";
import DemoFooter from "components/Footers/DemoFooter.js";

function ProfilePage1() {
  const [activeTab, setActiveTab] = React.useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ServicesNavbar/>
      <ProfilePageHeader1 />
      <div className="section profile-content">
        <Container>
          <div className="owner">
            <div className="avatar">
              <img
                alt="..."
                className="img-circle img-no-padding img-responsive"
                src={require("assets/img/faces/tarekprofile.jpg").default}
              />
            </div>
            <div className="name">
              <h4 className="title">
                Tarek El-Aissamy <br />
              </h4>
              <h6 className="description" style={{color:"#414242"}}>Founder & CEO / Digital marketing Specialist </h6>
            </div>
          </div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              <p style={{color:"black"}}>
               
              As the CEO of Tri-Advise, I provide and supply my clients with years of experience
               in marketing, advertising, sales, branding, product development, and finance.
                I have worked with corporations, as well as midsize and small businesses providing 
                them all same stage of leading digital facilities. I bring to the table an extensive
                network and expertise in digital marketing, branding, CRM, SEO, photography, 
                and social media management. 
                <ul>
                <li>
                Masters of Science in Marketing - University of Hertfordshire London
                <br/>
                Connect course - University of  Hertfordshire London
                </li>
                
                </ul>
              </p>
              
              <br />
              
            </Col>
          </Row>
         
          <br />
          <div className="nav-tabs-navigation">
            <div className="nav-tabs-wrapper">
              <Nav role="tablist" tabs>
                <NavItem>
                  <NavLink
                    className={activeTab === "1" ? "active" : ""}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Contact Info
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab === "2" ? "active" : ""}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Quote
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
          {/* Tab panes */}
          <TabContent className="following" activeTab={activeTab}>
            <TabPane tabId="1" id="follows">
              <Row>
                <Col className="ml-auto mr-auto" md="6">
                  <ul className="">
                    <li>
                      <Row>
                        <Col className="ml-auto mr-auto" lg="2" md="4" xs="4">
                        <i className="fa fa-envelope" /> 

                        </Col>
                        <Col className="ml-auto mr-auto" lg="7" md="4" xs="4">
                          <h6>
                            Mail <br />
                            <small style={{color:"black"}}>tarek.ayssamy@tri-advise.com</small>
                          </h6>
                        </Col>
                        <Col className="ml-auto mr-auto" lg="3" md="4" xs="4">
                          
                        </Col>
                      </Row>
                    </li>
                    <hr />
                    <li>
                      <Row>
                        <Col className="mx-auto" lg="2" md="4" xs="4">
                        <i className="fa fa-linkedin" /> 

                          
                        </Col>
                        <Col lg="7" md="4" xs="4">
                          
                        <h6>
                            LinkedIn <br />
            
                            <a  
              href="https://www.linkedin.com/in/tarek-el-aissami-345917125"
              target="_blank"
              rel="noreferrer"
              
            ><small style={{color:"black"}}> Tarek El-Aissamy</small></a>
                      
                          </h6>
                      
                        </Col>
                        <Col lg="3" md="4" xs="4">
                          
                        </Col>
                      </Row>
                    </li>
                  </ul>
                </Col>
              </Row>
            </TabPane>
            <TabPane className="text-center" tabId="2" id="following">
            <blockquote className="blockquote">
            <p className="mb-0" style={{color:"black"}}>
            "Marketing is a race without a finishing line." 
            </p>
            <br />
            <footer className="blockquote-footer" style={{color:"black"}}>
              {" "}
              <cite title="source Title" style={{color:"black"}}>Philip Kotler</cite>
            </footer>
          </blockquote>
            </TabPane>
          </TabContent>
        </Container>
      </div>
      <DemoFooter />
    </>
  );
}

export default ProfilePage1;
