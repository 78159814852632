import React from 'react'

const Section = () => {
    return (
        <div className="section section-dark">
        <div className="section section-dark">
        </div>
        </div>
    )
}

export default Section
