
import React from "react";

// reactstrap components
import {
    Button,
    Container,
    Row,
    Col,
} from "reactstrap";


import SectionCarousel from 'views/index-sections/SectionCarousel';

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import CoursePageHeader from "components/Headers/CoursePageHeader.js";

import DemoFooter from "components/Footers/DemoFooter.js";


function Course() {
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("profile-page");
        return function cleanup() {
            document.body.classList.remove("profile-page");
        };
    });
    return (
        <>
            <ExamplesNavbar />
            <CoursePageHeader />

            <div id="our-mission">
                <div className="section " >
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="8">
                                <h3 className="title" color="danger"><b>Welcome to our Digital Marketing Course! </b> </h3>
                                <br />

                                <p className="title" style={{ fontSize: "17px", fontWeight: "400" }}>
                                    <br />
  Are you ready to take your business to the next level? Our Digital Marketing Course will give you the skills and knowledge needed to successfully launch and grow your business in the digital age.

  <br />
                                    <br />

                                Led and taught by our experienced Marketing Manager, with a wealth of experience and higher education from London Universities, our Digital Marketing Course will equip you with the necessary tools and skills to market your business more effectively.
  <br />



                                You will learn how to create an effective digital marketing strategy, understand the latest trends and technologies, develop a better understanding of consumer behaviour, and more.
                            <br />
                                    <br />
                                This 8-session online course will provide comprehensive learning with each session lasting between 60 and 75 minutes.
  <br />

                                    <br />


                                Here's what you'll be learning:

                                    <li>Affiliate marketing</li>


                                    <li> Display advertising</li>
                                    <li>Email Marketing
                                    </li>

                                    <li>
                                        Search engine Marketing
                                    </li>
                                    <li>
                                        Social Media Marketing
                                    </li>
                                    <li>
                                        In-Game advertising
                                    </li>
                                    <li>Online public relations
                                    </li>
                                    <li> Video advertising
                                    </li>
                                    <li>Content Marketing
                                    </li>
                                    <li> Native Advertising
                                    </li>
                                    <li>Sponsored content
                                    </li>
                                    <li>SMS Marketing
                                    </li>
                                    <li>Push Notification
                                    </li>
                                    <li>Social Media strategies
                                    <br />(Facebook, Instagram, LinkedIn, TikTok, & Snapchat)
                                    </li>
                                    <li>Entrepreneurship
                                    </li>
                                    <li>Startup business strategies
                                    </li>
                                    <li>Google ads account management
                                    </li>
                                    <li>Facebook ads
                                    </li>
                                    <li>Posts promotions strategies
                                    </li>
                                    <li>KPI’s
                                    </li>

                                    <br />
                                    <br />
                                    Don't wait any longer, join our Digital Marketing Course today and give your business the boost it needs to succeed!
                                    <br />
                                    <br />

                                </p>
                                <Button
                                    className="btn-round"
                                    color="danger"
                                    href="https://api.whatsapp.com/send/?phone=%2B96181906656&text&type=phone_number&app_absent=0"
                                    target="_blank"
                                >
                                    Enroll Now
                </Button>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <SectionCarousel />

            <DemoFooter />
        </>
    );
}

export default Course;
