import React from 'react'

import {

  Container,
  Row,
  Col,
  Button,
} from "reactstrap";

import ServicesPageHeader from "components/Headers/ServicesPageHeader.js";

import DemoFooter from "components/Footers/DemoFooter.js";

import ServicesNavbar from 'components/Navbars/ServicesNavbar';

const Services = () => {
  return (
    <div>
      <ServicesNavbar />
      <ServicesPageHeader />



      <Container className="section text-center">
        <Row>
          <Col className="ml-auto mr-auto" md="8">
            <h2 className="title" ><b>Services</b></h2>


            <h5 className="description" style={{ color: "#414242" }}>
              We do it all, consistently and cohesively. Tri-Advise gives you
            considerable added value that you can rely on to move your business forward.</h5>
          </Col>
        </Row>

      </Container>
      <div id="images">
        <Container>

          <Row>

            <Col className="mr-auto ml-auto" md="2" sm="3">

              <img
                alt="..."
                className="img-thumbnail img-no-padding img-responsive"
                src={require("assets/img/brand.jpg").default}
              />
              <br />

              <div className="img-details text-center">
                <br /> <Button color="danger" outline size="lg">
                  Branding   </Button>

              </div>        </Col>
            <Col className="mr-auto ml-auto" md="2" sm="3">
              <img
                alt="..."
                className="img-thumbnail img-no-padding img-responsive"
                src={require("assets/img/advertising.jpg").default}
              />
              <br />
              <div className="img-details text-center">
                <br />
                <Button color="danger" outline size="lg">
                  Advertising   </Button>

              </div>
            </Col>
            <Col className="mr-auto ml-auto" md="2" sm="3">
              <img
                alt="..."
                className="img-thumbnail img-no-padding img-responsive"
                src={require("assets/img/marketing.jpg").default}
              />
              <br />

              <div className="img-details text-center">
                <br /><Button color="danger" outline size="lg">
                  Digital marketing   </Button>
                <br />
              </div>
            </Col>

          </Row>
          <Row>

            <Col className="mr-auto ml-auto" md="2" sm="3">

              <img
                alt="..."
                className="img-thumbnail img-no-padding img-responsive"
                src={require("assets/img/web.jpg").default}
              />
              <br />

              <div className="img-details text-center">
                <br /><Button color="danger" outline size="lg">
                  Web Development   </Button><br />
              </div>        </Col>
            <Col className="mr-auto ml-auto" md="2" sm="3">
              <img
                alt="..."
                className="img-thumbnail img-no-padding img-responsive"
                src={require("assets/img/video.jpg").default}
              />
              <br />
              <div className="img-details text-center">
                <br />
                <Button color="danger" outline size="lg">
                  Video Production   </Button>
              </div>
            </Col>
            <Col className="mr-auto ml-auto" md="2" sm="3">
              <img
                alt="..."
                className="img-thumbnail img-no-padding img-responsive"
                src={require("assets/img/photography.jpg").default}
              />
              <br />

              <div className="img-details text-center">
                <br /><Button color="danger" outline size="lg">
                  Photography
   </Button>
              </div>
            </Col>

          </Row>
          <Row>

            <Col className="mr-auto ml-auto" md="2" sm="3">

              <img
                alt="..."
                className="img-thumbnail img-no-padding img-responsive"
                src={require("assets/img/social.jpg").default}
              />
              <br />

              <div className="img-details text-center">
                <br /><Button color="danger" outline size="lg">
                  Social Media
     </Button>
              </div>        </Col>
            <Col className="mr-auto ml-auto" md="2" sm="3">
              <img
                alt="..."
                className="img-thumbnail img-no-padding img-responsive"
                src={require("assets/img/design.jpg").default}
              />
              <br />
              <div className="img-details text-center">
                <br />
                <Button color="danger" outline size="lg">
                  Graphic Design
  </Button>
              </div>
            </Col>
            <Col className="mr-auto ml-auto" md="2" sm="3">
              <img
                alt="..."
                className="img-thumbnail img-no-padding img-responsive"
                src={require("assets/img/seo.jpg").default}
              />
              <br />

              <div className="img-details text-center">
                <br />
                <Button color="danger" outline size="lg">
                  SEO
   </Button>
              </div>
            </Col>

          </Row>
          <Row>

            <Col className="mr-auto ml-auto" md="2" sm="3">

              <img
                alt="..."
                className="img-thumbnail img-no-padding img-responsive"
                src={require("assets/img/consultancy.jpg").default}
              />
              <br />

              <div className="img-details text-center">
                <br /><Button color="danger" outline size="lg">
                  Consultancy
  </Button>
                <h5><b></b></h5>
              </div>        </Col>
            <Col className="mr-auto ml-auto" md="2" sm="3">
              <img
                alt="..."
                className="img-thumbnail img-no-padding img-responsive"
                src={require("assets/img/content.jpg").default}
              />
              <br />
              <div className="img-details text-center">
                <br /><Button color="danger" outline size="lg">
                  Business Development
</Button>

              </div>
            </Col>
            <Col className="mr-auto ml-auto" md="2" sm="3">
              <img
                alt="..."
                className="img-thumbnail img-no-padding img-responsive"
                src={require("assets/img/businessdev.jpg").default}
              />
              <br />

              <div className="img-details text-center">
                <br /> <Button color="danger" outline size="lg">
                  SMS Marketing
</Button>

              </div>
            </Col>

          </Row>
          <br />
          <br />
          <br />
          <br />
        </Container>
      </div>
      <DemoFooter />
    </div>
  )
}

export default Services

