import React from 'react'
import { Button, Container, Row, Col, UncontrolledTooltip } from "reactstrap";
import Clients2 from 'views/index-sections/Clients2';
import Testimonial from 'views/index-sections/Testimonial';



const Clients = () => {
    return (
        <div id="images">
          <Container>
            <div className="title text-center">
            <br/>
            <br/>
            <h2><b>Our Clients</b></h2>
            <br/>
            <Row className="text-center">
            <Col className="ml-auto mr-auto" md="8">
              {/*<h5 className="description"  style={{color:"black"}}>
              Our clients have complete confidence and firm reliance on the integrity of our service.{" "}
              This makes us very happy indeed and could explain why we have such{" "}
              <a className="text-danger" disabled href>
              superb client retention records. 
                </a><br/>
                Trust is earned through experience. 
                At Tri-Advise we strive to deliver a service that keeps our clients satisfied no matter what.
    </h5> */}
            </Col>
            </Row>
            </div>
            <Clients2/>
            <br/>
            <br/>
            <br/>
           {/*} <h3 className="text-center"><b>Testimonials</b></h3> */}

            <Testimonial/>
            <br/>
            <br/>
            <br/>
{/*
            <Row>
            
              <Col className="mr-auto ml-auto" md="3" sm="4">
                <h3 className="text-center"><b>Saifan</b></h3>
                <br/>
                <img
                  alt="..."
                  className="img-circle img-no-padding img-responsive"
                  src={require("assets/img/faces/saifanlogo.png").default}
                />
              <br/>
              <br/>
                
                <p className="text" style={{color:"black"}}>
                The reason we chose Tri-Advise over other companies was their value for 
                great customer service and their focus on having interactive and engaging
                websites and social media. 
                </p>
              </Col>
              <Col className="mr-auto ml-auto" md="3" sm="4">
              <h3 className="text-center"><b>Evora Fashion</b></h3>
              <br/>
              <img
                alt="..."
                className="img-circle img-no-padding img-responsive"
                src={require("assets/img/faces/evoralogo.jpg").default}
              /> 
              <br/>
              <br/>
              <p className="text" style={{color:"black"}}>
              Greatest appreciation to you and your team for the outstanding job you did for us. We feel privileged to have Tri-Advise as our online marketing partner!              </p>
              </Col>
            <Col className="mr-auto ml-auto" md="3" sm="4">
            <h3 className="text-center"><b>Fitness Garage</b></h3>
            <br/>
            <img
              alt="..."
              className="img-circle img-no-padding img-responsive"
              src={require("assets/img/faces/fitnesslogo1.png").default}
            />    
            <br/>
            <br/>


            <p className="text" style={{color:"black"}}>
            <br/>
            <br/>
            I am very satisfied!  Enjoyable to work with, and most importantly, enabled us to have a strong online presence.            </p>
            </Col>
              
            </Row>

            <Row>
            
              <Col className="mr-auto ml-auto" md="2" sm="3">
                <h3 className="text-center"><b>Tafasil</b></h3>
                <br/>
                <img
                  alt="..."
                  className="img-circle img-no-padding img-responsive"
                  src={require("assets/img/faces/tafasillogo.jpg").default}
                />
              <br/>
                
                <p className="text" style={{color:"black"}}>
                <br/>
            
                Tri-Advise team Communications is attentive, personable, and professional. The team provides great support for my company's needs.
                </p>
              </Col>
              <Col className="mr-auto ml-auto" md="2" sm="3">
              <h3 className="text-center"><b>Health Talks</b></h3>
              <br/>
              <img
                alt="..."
                className="img-circle img-no-padding img-responsive"
                src={require("assets/img/faces/healthlogo.jpg").default}
              /> 
              <br/>
              <br/>
              <p className="text" style={{color:"black"}}>
              The team guidance and advice was invaluable in managing Social media accounts... we were thrilled with the way Tri-advise interacts. Without any hesitation... highly recommended.

              </p>
              </Col>
            <Col className="mr-auto ml-auto" md="2" sm="3">
            <h3 className="text-center"><b>DAB BBQ</b></h3>
            <br/>
            <img
              alt="..."
              className="img-circle img-no-padding img-responsive"
              src={require("assets/img/faces/dablogo.jpg").default}
            />    
            <br/>
            <br/>


            <p className="text" style={{color:"black"}}>
            Always available to answer any questions. Very knowledgeable about the services they provide. Would recommend it to anyone!
            </p>
            </Col>
            <Col className="mr-auto ml-auto" md="2" sm="3">
            <h3 className="text-center"><b>Delora</b></h3>
            <br/>
            <img
              alt="..."
              className="img-circle img-no-padding img-responsive"
              src={require("assets/img/faces/deloralogo.jpg").default}
            />    
            <br/>


            <p className="text" style={{color:"black"}}>
            <br/>
          
            With QR code, it is easy as pie for our clients to scroll our Menu, and who is better than Tri-Advise to do it ? 
            </p>
            </Col>
            </Row>
            <Row>
            <Col className="mr-auto ml-auto" md="2" sm="3">
            <h3 className="text-center"><b>A-LWS</b></h3>
            <br/>
            <img
              alt="..."
              className="img-circle img-no-padding img-responsive"
              src={require("assets/img/faces/awlslogo.jpg").default}
            />    
            <br/>
            <br/>


            <p className="text" style={{color:"black"}}>
            It's always a pleasure to work with Tri-Advise and his team. They are personable, responsive, and results-oriented!
            </p>
            </Col>
            <Col className="mr-auto ml-auto" md="2" sm="3">
            <h3 className="text-center"><b>AbeBarber</b></h3>
            <br/>
            <img
              alt="..."
              className="img-circle img-no-padding img-responsive"
              src={require("assets/img/faces/barberlogo.png").default}
              />    
            <br/>
            <br/>


            <p className="text" style={{color:"black"}}>
            On the social media front, there is no way we would have been able to gain the successes we have had on Facebook, Twitter, and LinkedIn without Tri-Advise.
            </p>
            </Col>
            <Col className="mr-auto ml-auto" md="2" sm="3">
            <h3 className="text-center"><b>IceBit</b></h3>
            <br/>
            <img
              alt="..."
              className="img-circle img-no-padding img-responsive"
              src={require("assets/img/faces/icebitlogo.png").default}
            />    
            <br/>
            <br/>


            <p className="text" style={{color:"black"}}>
            Tri-Advise is a remarkable Digital Marketing Agency.
            </p>
            </Col>
            <Col className="mr-auto ml-auto" md="2" sm="3">
            <h3 className="text-center"><b>GSM</b></h3>
            <br/>
            <img
              alt="..."
              className="img-circle img-no-padding img-responsive"
              src={require("assets/img/faces/gsmlogo.jpg").default}
            />    
            <br/>
            <br/>


            <p className="text" style={{color:"black"}}>
            It is a pleasure working with Tri-Advise. 
            The team is extremely knowledgeable, business savvy, and positive.
            Their approach to challenges and opportunities is innovative. They are highly productive and consistently deliver as promised. 
            And to top it all off, they are gracious, kind, and insightful.            </p>
            </Col>
              
            </Row>
*/}
            <Row className="justify-content-md-center sharing-area text-center">
            <Col className="text-center" lg="8" md="12">
              <h3 style={{color:"black"}}>Thank you for supporting us!</h3>
            </Col>
            <Col className="text-center" lg="8" md="12">
              <Button
                className="twitter-sharrre btn-round"
                color="twitter-bg"
                href="https://twitter.com/triadvise"
                id="tooltip3373767"
                target="_blank"
              >
                <i className="fa fa-twitter" /> Twitter
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip3373767">
                Tweet!
              </UncontrolledTooltip>
              <Button
                className="linkedin-sharrre btn-round  ml-2"
                color="google-bg"
                href="https://www.youtube.com/channel/UCpxTq-YDUXuEjw97mPtl3Tw"
                target="_blank"
                id="tooltip840791273"
              
              >
                <i className="fa fa-youtube-play" /> Youtube
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip840791273">
                Subscribe!
              </UncontrolledTooltip>
              <Button
                className="facebook-sharrre btn-round ml-2"
                color="facebook-bg"
                href="https://www.facebook.com/TriAdvise/"
                target="_blank"
                id="tooltip68961360"
              
              >
                <i className="fa fa-facebook-square" /> Facebook
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip68961360">
               Like!
              </UncontrolledTooltip>
              <Button
                className="sharrre btn-round ml-2"
                color="danger"
                href="https://www.instagram.com/tri_advise/"
                target="_blank"
                id="tooltip864353654"
              >
                <i className="fa fa-instagram" /> Instagram
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip864353654">
                Follow!
              </UncontrolledTooltip>
            </Col>
          </Row>
          </Container>
          <br/>
        <br/>
        <br/>

        </div>
    )
}

export default Clients
