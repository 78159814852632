
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

import SectionNucleoIcons from 'views/index-sections/SectionNucleoIcons';
import Clients from 'views/index-sections/Clients';


// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import SectionDark from "views/index-sections/SectionDark";
import SectionDark2 from "views/index-sections/SectionDark2";
import Section from "views/index-sections/Section";


function LandingPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <LandingPageHeader />
      <Section />
      <SectionDark />
      <SectionDark2 />
      <SectionNucleoIcons />

      <div className="main">
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title" ><b>Let's talk services</b></h2>

                <h5 className="description" style={{ color: "black" }}>
                  Join our Employment group for daily job vacancies from all around the world.
                </h5>
                <br />
                <Button
                  className="btn-round"
                  color="info"
                  href="https://chat.whatsapp.com/FYhyTQAInGk7ygMn2wiGTs"
                  target="_blank"
                >
                  Join Group
                </Button>
              </Col>
            </Row>

            <br />
            <br />
            <Row>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-touch-id" />
                  </div>
                  <div className="description">
                    <h4 className="info-title" style={{ color: "black" }}>Branding</h4>
                    <br />
                    {/*
                    <p className="description" style={{color:"black"}}>
                    "Branding is endowing products and services with the power of a brand"
                     ( Kotler & Keller, 2015). 
                    <br/>
                    We design strategies that engrave your business products in every consumer 
                    mind by helping people to quickly identify and experience your brand. 
                    </p>
                  */}
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-mobile" />
                  </div>
                  <div className="description">
                    <h4 className="info-title" >Advertising</h4>
                    <br />
                    {/*
                    <p style={{color:"black"}}>
                    "Advertising is any paid form of non-personal presentation & promotion of ideas, 
                    goods, or services by an identified sponsor" ( Kotler ) 
                      <br/>
                    Our experts are ready to shape the finest paid communication message intended to 
                    inform people  about your product or service in order to influence them to buy or try it. 
                    </p>
                  */}
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-globe" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Digital marketing</h4>
                    <br />
                    {/*
                    <p style={{color:"black"}}>
                    We deliver advertising through a wide range of digital channels,
                    such as search engines, websites, social media, email, and mobile apps. 
                    </p>
                    */}
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-laptop" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Web Development</h4>
                    <br />
                    {/*
                    <p style={{color:"black"}}>
                    In order to reach a wider audience, our web development team are ready 
                    to build a business website, that enables your products to be seen 24 hours a day, 
                    7 days a week even if your business isn't open, your Website will be! 
                    </p>
                    */}
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-button-play" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Video Production</h4>
                    <br />
                    {/*<p className="description" style={{color:"black"}}>
                    Also known as filmmaking, Tri-Advise provides you three stages of video
                    production: Pre-production, production, and post-production. 
                    </p>  
                    */}

                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-camera-compact" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Photography </h4>
                    <br />
                    {/*}
                    <p style={{color:"black"}}>
                    A picture is worth a thousand sales. Tri-Advise offer your business 
                     a professional photography that generates a high quality of your product
                    visual presentation. 

                    </p>
                  */}
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-single-02" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Social Media</h4>
                    <br />
                    {/*
                    <p style={{color:"black"}}>
                    While Social media platforms popularity is increasing on a daily basis,
                    Tri-Advise provides you the right tools to reach prospects and customers through 
                    social platforms like Facebook, Twitter, Instagram, YouTube, Tiktok and Pinterest. 
                    </p>
                    */}
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-palette" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Graphic Design</h4>
                    <br />
                    {/*
                    <p style={{color:"black"}}>
                    Tri-Advise graphic designers transmit specific messages with specific 
                    objectives to people in order to convince them to buy your products or 
                    services. 
                    </p>
                    */}
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-zoom-split" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">SEO</h4>
                    <br />
                    {/*
                  <p className="description" style={{color:"black"}}>
                  While 55% of businesses invest in professional search engine optimization
                  (SEO) services, Tri-Advise increases your business visibility in search results
                    on search engines like Google and Bing. 
                  </p>
                  */}
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-glasses-2" />
                  </div>
                  <div className="description">
                    <h4 className="info-title"> Consultancy</h4>
                    <br />
                    {/*
                  <p style={{color:"black"}}>
                  Tri-Advise provides you with expert advice in a broad range of domains 
                  for better business development. 
                  </p>
                  */}
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-spaceship" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Business Development </h4>
                    <br />
                    {/*
                  <p style={{color:"black"}}>
                  Quizzes, assessments, calculations, interactive infographics and interactive 
                  white papers will let your audience actively participate with content instead 
                  of just reading, watching, or listening.
                  </p>
                  */}
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-send" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">SMS Marketing</h4>
                    <br />
                    {/*
                  <p style={{color:"black"}}>
                  Tri-Advise develops your business by the creation of long-term value 
                  for your organization from customers, markets, and relationships.
                  </p>
                  */}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section section-dark text-center">
          <Container>
            <h2 className="title"><b>Meet Tri-Advise Team</b></h2>
            <Row>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="/team-tarek" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={
                          require("assets/img/faces/ninaprofile.jpg")
                            .default
                        }
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Nina Seifeddine</CardTitle>
                        <h6 className="card-category"> Head of Graphic Design Department </h6>
                      </div>
                    </a>
                    {/*
                    <p className="card-description text-center">
                    Something you may not be aware of is just how little time 
                    you have made that first impression, which could turn someone 
                    from a stranger to your new best friend. Literally seconds.
                    That one-tenth of a second starts the selling process and begins 
                    the relationship. This is the power in making the ten-second introduction 
                    more personable when users' eyes see the Post on any Marketing channel.
                      So this is what I do, I am a Graphic Designer.
                    </p>
                    */}
                  </CardBody>
                  <Button
                    className="btn-round  ml-1" color="neutral" outline
                    href="/team-nina"
                    target="_blank"

                  >
                    See More
                </Button>
                  <CardFooter className="text-center">


                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="https://www.linkedin.com/in/nina-seif-eddine-612581216"
                      target="_blank"
                    >
                      <i className="fa fa-linkedin" />
                    </Button>
                  </CardFooter>
                </Card>
              </Col>



              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="/" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={
                          require("assets/img/faces/hasanprofile.jpg").default
                        }
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="/" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Hasan Awwad</CardTitle>
                        <h6 className="card-category">Application Development Team Lead</h6>
                      </div>
                    </a>
                    {/*
                <p className="card-description text-center">
                Hard-working web developer with a flair for creating elegant solutions in the least amount of time.
                <br/>Passionate about UX Design and artificial intelligence. 
                
                </p>
                */}
                  </CardBody>

                  <Button
                    className="btn-round  ml-1" color="neutral" outline
                    href="/team-hasan"
                    target="_blank"

                  >
                    See More
            </Button>


                  <CardFooter className="text-center">


                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="https://www.linkedin.com/in/hasanawwad/"
                      target="_blank"

                    >
                      <i className="fa fa-linkedin" />
                    </Button>

                  </CardFooter>
                </Card>
              </Col>

              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="/" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        src={
                          require("assets/img/faces/nourprofile.jpg").default
                        }
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="/" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Nour Shams</CardTitle>
                        <h6 className="card-category">HEAD OF WEB DEVELOPMENT DEPARTMENT</h6>
                      </div>
                    </a>
                    {/*
              <p className="card-description text-center">
              Hard-working web developer with a flair for creating elegant solutions in the least amount of time.
              <br/>Passionate about UX Design and artificial intelligence. 
              
              </p>
              */}
                  </CardBody>

                  <Button
                    className="btn-round  ml-1" color="neutral" outline
                    href="/team-nour"
                    target="_blank"

                  >
                    See More
          </Button>


                  <CardFooter className="text-center">


                    <Button
                      className="btn-just-icon btn-neutral ml-1"
                      color="link"
                      href="https://www.linkedin.com/in/nourshams/"
                      target="_blank"

                    >
                      <i className="fa fa-linkedin" />
                    </Button>

                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

      </div>

      <Clients />

      <DemoFooter />
    </>
  );
}

export default LandingPage;
